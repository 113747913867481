/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 30, 2015 */



@font-face {
    font-family: 'popregular';
    src: url('pop-led-webfont.eot');
    src: url('pop-led-webfont.eot?#iefix') format('embedded-opentype'),
         url('pop-led-webfont.woff2') format('woff2'),
         url('pop-led-webfont.woff') format('woff'),
         url('pop-led-webfont.ttf') format('truetype'),
         url('pop-led-webfont.svg#popregular') format('svg');
    font-weight: normal;
    font-style: normal;

}